<template>
  <div>
    <div class='row'>
      <div class='col-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <vb-headers-card-header :data="{ title: 'Döküman Başlığı' }" />
          </div>
          <div class='card-body'>
            <a-input label='Başlık' name='title' v-model:value='title' />
            <a-button v-if='showTitleSaveButton' :loading='loading' @click='updateTitle' class='btn btn-success mt-3'>Güncelle</a-button>
          </div>
        </div>
      </div>
      <div class='col-lg-6 col-md-12'></div>
    </div>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-body'>
            <div>
              <div class='d-flex align-items-start'>
                <div class='card card-skip flex-grow-1'>
                  <div :class='$style.editor' class='card-body'>
                    <v-md-editor v-model='content' height='400px'></v-md-editor>

                    <div class='py-2 px-3'>
                      <a-button @click='updateContent' :loading='loading' v-if='showContentSaveButton' class='btn btn-success'> Kaydet</a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { legalDocumentAdministration } from '../../services/api-service'
import { notification } from 'ant-design-vue'
import { useRouter } from 'vue-router'

export default {
  name: 'VbLegalDKManDZenleme',
  components: {},
  data() {
    const router = useRouter()
    const queryId = parseInt(this.$route.params['id'])

    const formState = reactive({
      title: '',
      content: '',
    })

    return {
      router,
      queryId,
      content: '',
      contentFromServer: '',
      title: '',
      titleFromServer: '',
      formState,
      loading: false,
    }
  },
  computed: {
    showTitleSaveButton() {
      return this.title !== this.titleFromServer
    },
    showContentSaveButton() {
      return this.contentFromServer !== this.content
    },
  },
  mounted() {
    this.loadDocument()
  },
  methods: {
    async loadDocument() {
      this.loading = true

      try {
        const { data } = await legalDocumentAdministration.getAllLegalDocumentsForAdministration()
        const legalDocument = data.legalDocuments.find(f => f.id === this.queryId)

        if (legalDocument) {
          this.titleFromServer = legalDocument.title
          this.title = legalDocument.title
          this.contentFromServer = legalDocument.content
          this.content = legalDocument.content
        } else {
          notification.error({
            message: 'Döküman Bulunamadı',
          })

          await this.router.push({ path: '/legal-dokumanlar' })
        }

      } catch {
      }

      this.loading = false
    },
    async updateTitle() {
      this.loading = true

      try {
        await legalDocumentAdministration.changeTitleOfTheLegalDocument({ title: this.title, id: this.queryId })
        this.titleFromServer = this.title
      } catch {
      }

      this.loading = false
    },
    async updateContent() {
      this.loading = true

      try {
        await legalDocumentAdministration.changeContentOfTheLegalDocument({ content: this.content, id: this.queryId })
        this.contentFromServer = this.content
      } catch {
      }

      this.loading = false
    },
  },
}
</script>
<style lang='scss' module>
@import './style.module.scss';
</style>
